import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { SubjectService } from '../../services/subject.service';
import { BrowserService } from '../../services/browser.service';
import { SubSink } from 'subsink';
import { apiEndPoints } from '../../shared/api-endpoint';
import { actionIdConstants, general } from '../../shared/common.constants';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { SharedService } from '../../services/shared.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';

declare const grecaptcha: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  subs = new SubSink();
  errorPopup_login: boolean = false;
  closepopup: boolean = false;
  recaptchaActive = environment.recaptchaActive;
  
  get email(): AbstractControl {
    return this.LoginForm.get('email');
  }
  get password(): AbstractControl {
    return this.LoginForm.get('password');
  }
  public rememberMe: MatCheckboxChange;
  LoginForm: FormGroup;
  forgotPaasForm: FormGroup;
  forgot = false;
  error: any;
  forgetMessage: any = {};
  loading: boolean = false;
  returnUrl: string;
  cityList;
  showVar: boolean;
  showLogin = true;
  location: Location;

  constructor(
    public api: ApiService,
    private fb: FormBuilder,
    private router: Router,
    private alert: AlertService,
    private subject: SubjectService,
    private route: ActivatedRoute,
    private browser: BrowserService,
    private translate: TranslateService,
    private global: GlobalService,
    private sharedService: SharedService,
    @Inject(PLATFORM_ID) private platformId: any,

  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.location = window.location;
    }
    if (this.api.user_id) {
      
      if (isPlatformBrowser(this.platformId)) {
        let city = JSON.parse(this.browser.getLocalValue('city'));
        if(city){
          this.router.navigateByUrl(`${city.slug}/reports`);

        } else {
          this.router.navigateByUrl('/reports');

        }
      }
    }
    this.LoginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      recaptchaReactive: ['', Validators.required],
    });

    if(!this.recaptchaActive){
      this.LoginForm.get('recaptchaReactive').clearValidators();
      this.LoginForm.get('recaptchaReactive').updateValueAndValidity();
    }

    this.forgotPaasForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/reports';
  }

  jumpTo(to) {
    if (to == 'forgot') this.forgot = true;
    if (to == 'login') {
      this.forgetMessage = {};
      this.forgot = false;
    }
  }

  toggleEyeImage() {
    this.showVar = !this.showVar;
  }

  login(val: any) {
    if (this.LoginForm.invalid) {
      return;
    }
    if (this.rememberMe) {
      this.browser.setCookie('_r_m_', true, 30);
    }
    let cred = val.value;
    let record: any;
    this.error = null;
    let userInfo = {
      user_name: cred.email,
      user_password: cred.password,
      keep_log_in: this.rememberMe ? this.rememberMe : false,
    };
    this.loading = true;
    this.api.postUmsData('login', userInfo).subscribe(
      {next:(res: any) => {
        if (res && res.status == 200) {
          if(res.data.status != 1){
            this.loading = false;
            this.alert.error("Your account is currently in-active");
            return;
          }
          const activity = {
            userdata: res.data,
            actionId: actionIdConstants.User_Login,
            entity: 'login',
          };
          this.api.logUserActivity(activity);
          record = res.data;
          this.sharedService.setUserDetails(res.data);
          let first_name =
            record.user_details && record.user_details['1']
              ? record.user_details['1'].first_name
              : '';
          let last_name =
            record.user_details && record.user_details['1']
              ? record.user_details['1'].last_name
              : '';

          if (record.user_details && record.user_details[this.api.language]) {
            first_name = record.user_details[this.api.language].first_name;
            last_name = record.user_details[this.api.language].last_name;
          }

          this.browser.setLocalValue('user_token', record.token);
          this.browser.setLocalValue('user_id', record.user_id);
          this.browser.setLocalValue('created_date', record.created_date);
          this.browser.setLocalValue('profile_image', record.profile_image);
          this.browser.setLocalValue(
            'uname',
            first_name.trim() + ' ' + last_name.trim()
          );
          this.api.user_id = record.user_id;
          this.api.user_token = record.token;
          let user_info = {
            fname: first_name,
            lname: last_name,
            uname: first_name.trim() + ' ' + last_name.trim(),
            gender: record.gender ? record.gender : '',
            companies: record.companies ? record.companies : '',
            contact: record.phone_number ? record.phone_number : '',
            country: record.nationality ? record.nationality : '',
            email: record.primary_email_id ? record.primary_email_id : '',
            createdDate: record.created_date ? record.created_date : '',
            date_of_birth: record.date_of_birth ? record.date_of_birth : '',
            latitude: record.lat ? record.lat : '',
            longitude: record.lon ? record.lon : '',
            language: 'en',
            imageURL: record.profile_image ? record.profile_image : '',
          };
          this.browser.setLocalValue('user_info', JSON.stringify(user_info));
          this.subject.profileImage.next(user_info.imageURL);
          this.subject.userName.next(user_info.fname + ' ' + user_info.lname);
          this.subject.userInfo.next(user_info);
          this.getCityList();
        } else {
          this.alert.error(res.message);
          this.loading = false;
        }
      },
      error:(err) => {
        this.loading = false;
        this.error = err.message;
      }}
    );
  }

  getCityandUserSetting() {
    this.subject.initialAppLoader.next(true);
    this.subs.sink = this.api
      .getUserSetting(
        `user-setting?user_id=${this.api.user_id}`
      )
      .subscribe(
        {next: (response: any) => {
          this.loading=false;
          if (response && response.status == 200) {
            this.setCityandLanguage(response);
          } else {
            this.setCityandLanguage(null);
          }
        },
        error: (err) => {
          this.loading = false;
          this.setCityandLanguage(null);
        }}
      );
  }

  getCityList() {
    let url = `place`;
    this.cityList = [];
    this.api.getUmsData(url).subscribe({next: (res: any) => {
      if (res && res.status == 200) {
      
        let client_host = this.location.hostname;
          res.data.sort((a, b) => a.place_name.localeCompare(b.place_name));
          res.data.map((city: any) => {
            var temp = new Array();
            var str = city.domain ? city.domain : '';
            temp = str ? str.split(',') : [];
            if (city.status == 1 && temp.indexOf(client_host) > -1) {
              this.cityList.push(city);
            }
          });

        this.browser.setLocalValue('city_list', JSON.stringify(this.cityList));
        this.subject.cityList.next(this.cityList);
        this.api.cityList = this.cityList;
        this.getCityandUserSetting();
      } else {
        this.cityList = [];
        this.loading=false;
      }
    },error:(err)=>{
      this.loading=false;
      if (err.status == 403) {
        this.cityList = [];
      }
    }});
  }

  setCityandLanguage(res) {
    if (res && res.status == 200 && res.data) {
      const lang = res.data.find((value) => value.key === 'default_language');
      const place = res.data.find((value) => value.key === 'default_place');
      const langInfo =
        lang && lang.value !== 'all'
          ? general.language[Number(lang.value)]
          : general.defaultLang;
      const placeInfo =
        place && place.value !== 'all'
          ? this.cityList.find((ele) => ele.place_id == place.value)
          : null;
      this.api.language = langInfo.language_id;
      if (placeInfo) {
        this.api.place_id = placeInfo.place_id;
        this.api.getUserRole();
        this.browser.setLocalValue('place_id', placeInfo.place_id);
        this.browser.setLocalValue('city', JSON.stringify(placeInfo));
        if(this.returnUrl === '/reports'){
          this.returnUrl = `${placeInfo.slug}/reports`;
        }
      }
      this.browser.setLocalValue('language_id', langInfo.language_id);
      this.browser.setLocalValue('language', langInfo.language_code);
      this.browser.setLocalValue('selected_language', JSON.stringify(langInfo));
      this.translate.use(langInfo.language_code);
      this.translate.setDefaultLang(langInfo.language_code);

      this.global.setLanguageDoc(langInfo, true);
    } else {
      const langInfo = general.defaultLang;
      this.browser.setLocalValue('language_id', langInfo.language_id);
      this.browser.setLocalValue('language', langInfo.language_code);
      this.browser.setLocalValue('selected_language', JSON.stringify(langInfo));
      this.translate.use(langInfo.language_code);
      this.translate.setDefaultLang(langInfo.language_code);

      this.global.setLanguageDoc(langInfo, true);
    }
    this.router.navigateByUrl(this.returnUrl);
    this.alert.success('User Logged in Successfully', {
      keepAfterRouteChange: true,
    });
    this.subject.initialAppLoader.next(false);
  }

  resetPassword(value) {
    let body = {
      email: value.value.email,
      notification_type: 'forgot_password',
    };
    // let body = "email=" + this.forget_email + "&notification_type=forgot_password";
    this.loading = true;
    this.api.postUmsData('notification', body).subscribe(
      (data: any) => {
        this.loading = false;
        if (data && data.status == 201) {
          this.forgetMessage = {
            class: 'text-success',
            m: 'Reset password link has been sent to your email',
          };
          this.forgotPaasForm.reset();
        } else {
          this.forgetMessage = { class: 'text-danger', m: data.message };
        }
      },
      (err) => {
        this.loading = false;
        this.forgetMessage = { class: 'text-danger', m: err.message };
      }
    );
  }

  executeRequest(token) {
    this.api.getResult(token).subscribe((resultado) => {
      console.warn(resultado);
    });
  }

  login_mob() {
    this.errorPopup_login = !this.errorPopup_login;
  }

  close_errorpopup() {
    this.closepopup = !this.closepopup;
  }

  changeLogin(){
    this.showLogin = !this.showLogin;
  }

  changeLoginForm(event){
    this.showLogin = event;
  }

  ngOnDestroy(): void {
    this.loading = false;
  }
}
